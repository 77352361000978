import {calculateReferrer} from "@pp-frontend/shared-packages/utils";

export interface GoogleAnalyticsPlugin {
    /**
     * @todo описать
     * @param forAnalytics
     */
    getStorage(forAnalytics?: boolean): any

    /**
     * @todo описать
     */
    updateStorage(): void

    /**
     * @todo описать
     * @param category
     * @param event
     * @param value
     */
    sendEvent(category: string, event: string, value?: any): void
}

declare module '@nuxt/types' {
    interface Context {
        $ga: GoogleAnalyticsPlugin
    }

    interface NuxtAppOptions {
        $ga: GoogleAnalyticsPlugin
    }
}
declare module 'vue/types/vue' {
    interface Vue {
        $ga: GoogleAnalyticsPlugin
    }
}

export default (ctx, inject) => {
    const STORAGE_KEY = 'analytics';
    const options = JSON.parse(`{"id":"","params":[],"query":["lead_source_id","lead_source_id_ext"],"static_page_routes":["articles"],"ym_id":83947918}`);
    const appHasStaticLeadSources = options.static_page_routes && options.static_page_routes.length

    function getStorage(forAnalytics = false) {
        // @ts-ignore
        const result = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}

        if (forAnalytics && result.lead_source_id) {
            result.el = result.lead_source_id
        }

        delete result.lead_source_id
        return result
    }

    function updateStorage() {
        const result = getStorage()
        for (let param of options.params) {
            result[param] = ctx.app.context.route.query[param] || result[param]
        }

        result.lead_source_id = ctx.app.context.route.query.lead_source_id || result.lead_source_id || 'site_search'

        localStorage.setItem(STORAGE_KEY, JSON.stringify(result))
    }

    function sendEvent(category, event, value = {}) {
        const waitForLocalStorage = function (key, cb, timer: any = null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            if (!localStorage.getItem(key)) {
                return (timer = setTimeout(waitForLocalStorage.bind(null, key, cb), 100))
            }
            clearTimeout(timer)
            if (typeof cb !== 'function') {
                return localStorage.getItem(key)
            }
            return cb(localStorage.getItem(key))
        }

        waitForLocalStorage('storedToken', function (authToken) {
            const requestParams = Object.assign({
                v: 1,
                tid: options.id,
                cid: authToken,
                t: 'event',
                ec: category,
                ea: event
            }, getStorage(true), value)

            try {
                if (process.env.DEV_MODE) {
                    // eslint-disable-next-line no-console
                    console.log('sending ga event', requestParams)
                }
                ctx.app.$axios.post('/collect', require('qs').stringify(requestParams), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            } catch (ignore) {
            }
        })
    }

    function calculateAnalyticQuery(to, from) {
        let analyticQuery: { [key: string]: string } = {}
        // обработать запуск приложения - если нет лидсорцов - определить по document.referrer
        const appHasLeadSourceId = !!ctx.route.query.lead_source_id
        if (!appHasLeadSourceId && from.name === null) {
            analyticQuery = {...calculateReferrer(document.referrer)}
        }

        // проходим по всем query параметрам указаным в модуле аналитики
        for (const option of options.query) {
            // каждый параметр из аналитики берем по приоритетам
            // 1 - присвоенный
            // 2 - тот на который переходим
            // 3 - тот что забыли передать
            analyticQuery[option] = analyticQuery[option] || to.query[option] || from.query[option]
        }

        // проверяем есть ли в настройках static_page_routes
        // и если пользователь выходит из роутов которые указаны в настройках модуля на другие страницы
        const navigatedFromStaticPage = options.static_page_routes.some(item => from.path.startsWith(`/${item}`))
        if (appHasStaticLeadSources && navigatedFromStaticPage) {
            analyticQuery.lead_source_id_ext = 'static_page'
        }

        return analyticQuery
    }

    function requestAnalyticsInterceptor(config) {
        const params = {}
        for (const query of options.query) {
            params[query] = ctx.app.router.currentRoute.query[query]
        }

        config.params = {...params, ...config.params}
        return config
    }

    ctx.app.router.beforeEach((to, from, next) => {
        let analyticQuery = {...calculateAnalyticQuery(to, from)}
        let appRedirect = to.path.includes('/app')

        if (options.query.every(option => analyticQuery[option] === to.query[option]) || appRedirect) {
            next()
        } else {
            next({...to, query: {...to.query, ...analyticQuery}})
        }
    })

    ctx.$axios.onRequest(requestAnalyticsInterceptor)
    ctx.$api.onRequest(requestAnalyticsInterceptor)

    inject('ga', {
        getStorage,
        updateStorage,
        sendEvent
    })
}
