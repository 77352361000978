
import { Component, Vue } from 'nuxt-property-decorator'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'

@Component
export default class extends Vue {
  mounted () {
    // const gtm = this.$gtm
    const eventBus = this.$eventBus

    /**
     * преход к поиску
     */
    eventBus.$on(Events.SEARCH_FORM_SUBMIT, () => {})

    /**
     * результат поиска
     */
    eventBus.$on(Events.SEARCH_RESULT, () => {})

    /**
     * посадка с письма
     */
    eventBus.$on(Events.EMAIL_RESULT, () => {
      // gtm.push({ event: 'email_landing' })
    })

    /**
     * посадка с Гибдд
     */
    eventBus.$on(Events.GIBDD_RESULT, () => {
      // gtm.push({ event: 'gibdd_landing' })
    })

    /**
     * офомление подписки
     */
    eventBus.$on(Events.SUBSCRIBE, () => {})

    /**
     * успешная оплата
     */
    eventBus.$on(Events.PAYMENT_SUCCESS, () => {})

    /**
     * Ошибка при оплате
     */
    eventBus.$on(Events.PAYMENT_FAIL, () => {})

    /**
     * подписка на браузерные пуши
     */
    eventBus.$on(Events.PUSH_SUBSCRIBE, () => {
      // gtm.push({ event: 'push_subscribe' })
    })

    /**
     * ввод данных пользователя
     */
    eventBus.$on(Events.PAYER_INFO_SUBMIT, () => {})
  }
}
